import { Component } from "react";
import { Route } from "react-router-dom";
import Auth from "./Auth/Auth";
import Home from "./Home";
import Nav from "./Nav";
import Profile from "./Profile";

class App extends Component {
  constructor(props) {
    super(props);
    this.auth0 = new Auth(this.props.history);
  }
  render() {
    return (
      <>
        <Nav />
        <Route
          path="/"
          exact
          render={(props) => <Home auth={this.auth0} {...props} />}
        ></Route>
        <Route
          path="/profile"
          exact
          render={(props) => {
            <Profile auth={this.auth0} {...props} />;
          }}
        ></Route>
      </>
    );
  }
}
export default App;
